<template>
  <div>
    <v-container>
      <h1 class="pb-6">Marketplace</h1>
      <v-row>
        <v-col v-for="(menu, idx) in menus" :key="idx" cols="6" md="3" sm="3">
          <v-card ripple link :to="'/' + menu.url" rounded="lg" class="pa-6">
            <v-img class="ma-6" :src="getImage(menu.img)"></v-img>
            <p class="text-center">{{ menu.title }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      menus: [
        {
          img: "search_marketplace.svg",
          title: "Search Marketplace",
        },
        {
          img: "my_listings.svg",
          title: "My Listings",
        },
        {
          img: "order_an_item.svg",
          title: "Order An Item",
        },
        {
          img: "receive_an_item.svg",
          title: "Receive An Item",
        },
        {
          img: "order_sensors.svg",
          title: "Order Sensors",
        },
        {
          img: "order_dna_kit.svg",
          title: "Order DNA Kit",
        },
        {
          img: "add_dimitra_features.svg",
          title: "Add Dimitra Features",
        },
        {
          img: "order_drones.svg",
          title: "Order Drones",
        },
        {
          img: "order_network_setup.svg",
          title: "Order Network Setup",
        },
        {
          img: "my_history.svg",
          title: "My History",
        },
        {
          img: "help.svg",
          title: "Help",
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return "/img/marketplace/" + img;
    },
  },
};
</script>
